.backdrop {
	width: 100%;
	height: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	opacity: 0.8;
	background-color: #1d1a1a;
}
.z_index_100 {
	z-index: 100;
}
.modal_title {
	border-radius: 8px 8px 0 0;
	-webkit-border-radius: 8px 8px 0 0;
	-moz-border-radius: 8px 8px 0 0;
	-ms-border-radius: 8px 8px 0 0;
	-o-border-radius: 8px 8px 0 0;
}

.BlogIcon path {
	fill: #c6c6c6;
}
