.question_content_scroll::-webkit-scrollbar,
.question_title_scroll::-webkit-scrollbar {
	width: 6px;
}

.question_content_scroll::-webkit-scrollbar-thumb,
.question_title_scroll::-webkit-scrollbar-thumb {
	background: var(--unnamed-color-119184);
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

/* The scrollbar thumb */

.question_content_scroll::-webkit-scrollbar-thumb:hover,
.question_title_scroll::-webkit-scrollbar-thumb:hover {
	background: var(--unnamed-color-119184);
}

.question_content_scroll::-webkit-scrollbar-track,
.question_title_scroll::-webkit-scrollbar-track {
	background: transparent;
}

.active {
	background-color: #b19f70;
	color: black;
}
