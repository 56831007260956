.backdrop {
	width: 100%;
	height: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	backdrop-filter: blur(10px);
}
.z_index_100 {
	z-index: 100;
}
