.backdrop {
	width: 100%;
	height: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	opacity: 0.8;
	background-color: #1d1a1a;
}
.z_index_100 {
	z-index: 100;
}

svg.close_video_icon {
	fill: #fff;
	width: 24px;
	height: 24px;
}
